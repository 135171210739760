import { memo } from 'react';

import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { DocumentsTypes } from 'api/types/documents';
import DisplayWrapper from 'components/DisplayWrapper';
import PageContent from 'components/PageContent/PageContent';
import useDocuments from 'hooks/useDocuments';
import useCustomNavigation from 'src/router/useNavigation/useNavigation';
import styles from './Documents.styles';

const Documents = memo(() => {
  const navigation = useCustomNavigation();
  const { data: policies, isLoading } = useDocuments({
    type: DocumentsTypes.POLICY,
  });

  return (
    <PageContent>
      <DisplayWrapper>
        <Box sx={styles.root}>
          <Typography variant="h1">Dokumenty</Typography>
          {isLoading ? (
            <Box sx={styles.loader}>
              <CircularProgress />
            </Box>
          ) : (
            <ul style={{ paddingLeft: '2rem' }}>
              {(policies ?? []).map(({ label, url: navigateTo }, i) => (
                <li key={`document-${i}`} style={{ marginBottom: '1rem' }}>
                  <Link
                    sx={styles.link}
                    onClick={() => navigation.navigate(navigateTo)}
                    target={'_blank'}
                  >
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </Box>
      </DisplayWrapper>
    </PageContent>
  );
});

Documents.displayName = 'Documents';

export default Documents;
