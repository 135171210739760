export enum DocumentsTypes {
  POLICY = 'POLICY',
  OTHER = 'OTHER',
}

export interface Document {
  id: number;
  type: DocumentsTypes;
  label: string;
  url: string;
}

export type DocumentsGetResponse = Array<Document>;
