export enum RouterPaths {
  home = '/',
  forgotPassword = '/forgot',
  holidayImages = '/holiday-images',
  kudos = '/kudos',
  myKudos = '/my_kudos',
  sentKudos = '/sent_kudos',
  login = '/login',
  newOffer = `/new-offer/:offerName`,
  offer = `/offer/:offerName`,
  print = '/print',
  projectsDepartments = '/projects-and-departments',
  resetPassword = '/reset_password/',
  profile = '/profile',
  sendPhoto = '/send-photo',
  documents = '/documents',
  deskReservation = '/desk-reservation',
  notFound = '/404',
  roomReservation = '/room-reservations',
  holidayCalendar = '/holiday-calendar',
}

export enum ExternalPaths {
  cultureBook = 'https://drive.google.com/drive/folders/1dPEDjQ8jl4ypopSpL_s9dSHvwPXn-its',
  companyPage = 'https://revolve.healthcare/',
}
