import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/axios';
import { API_URLS } from 'api/axios/axios.consts';
import { DocumentsGetResponse } from 'api/types/documents';
import { DocumentsOptions } from './useDocuments.types';

const useDocuments = ({ type }: DocumentsOptions) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [type],
    queryFn: async ({ queryKey: [type] }) => {
      const response = await apiGet<DocumentsGetResponse>(
        `${API_URLS.documents}?type=${type}`
      );

      return response.data;
    },
  });

  return { data, isLoading, refetch };
};

export default useDocuments;
